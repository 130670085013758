import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AspectRatio, Image, } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Box } from '../Box';
import { RouteLink } from '../Link';
import { Text } from '../Text';
import { shadows } from '../theme/foundations';
export const ThumbnailImage = (props) => {
    const [thumbLoaded, setThumbLoaded] = useState(false);
    const imageRef = useRef(null);
    useEffect(() => {
        if (!thumbLoaded && imageRef.current?.complete) {
            setThumbLoaded(true);
        }
    }, [thumbLoaded]);
    return (_jsx(Image, { ref: imageRef, onLoad: () => setThumbLoaded(true), objectFit: 'cover', objectPosition: 'top', ...props, loading: 'lazy' }));
};
// safari is where videos go to die
export const ThumbnailVideo = (props) => {
    const { src, ...rest } = props;
    const videoRef = useRef(null);
    const playingPromise = useRef();
    const isOver = useRef(false);
    function resetVideo() {
        // don't call pause() or load() if the video is still trying to play
        if (!videoRef.current || playingPromise.current)
            return;
        // if we have a poster, call load() so that we can show the poster again
        if (props.poster) {
            videoRef.current.load();
            return;
        }
        // otherwise we can just pause and rewind
        videoRef.current.pause();
        // reset video to first frame
        // i've noticed that setting it to 0 can result with a black frame on safari, so seems like 0.01 is a safer bet.
        // this could also be related to our videos, maybe exactly 0 is before the first frame, unclear.
        videoRef.current.currentTime = 0.01;
    }
    // playing videos is async so we need to do a little more work to ensure we don't call pause or load before it actually plays.
    const playVideo = async () => {
        if (!videoRef.current || playingPromise.current)
            return;
        try {
            playingPromise.current = videoRef.current.play();
            playingPromise.current = await playingPromise.current;
            if (!isOver.current)
                handleOut();
        }
        catch (e) {
            console.warn('Error playing video:', e);
            playingPromise.current = undefined;
        }
    };
    const handleOut = () => {
        isOver.current = false;
        resetVideo();
    };
    const handleOver = () => {
        isOver.current = true;
        playVideo();
    };
    const observer = useRef(null);
    useEffect(() => {
        if (!videoRef.current)
            return;
        const video = videoRef.current;
        observer.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (!videoRef.current || !entry.isIntersecting)
                    return;
                if ('requestIdleCallback' in window) {
                    window.requestIdleCallback(() => video?.load());
                }
                else {
                    // safari ain't got requestIdleCallback why would they amirite????
                    video.load();
                }
                observer.current?.unobserve(videoRef.current);
            });
        }, {
            rootMargin: '100px',
        });
        observer.current.observe(video);
        return () => {
            observer.current?.unobserve(video);
        };
    }, [src]);
    return (_jsx(Box, { as: 'video', 
        // the absurdity we go through to stop safari flickering when we call video.load()
        // it's still not perfect 100% of the time but overall it's better
        backgroundImage: props.poster, backgroundSize: 'cover', backgroundPosition: 'center', sx: {
            WebkitTransform: 'translate3d(0, 0, 0)',
            WebkitBackfaceVisibility: 'hidden',
        }, ref: videoRef, src: src, loop: true, muted: true, preload: 'none', playsInline: true, disablePictureInPicture: true, disableRemotePlayback: true, onMouseEnter: handleOver, onTouchStart: handleOver, onFocus: handleOver, onMouseLeave: handleOut, onTouchEnd: handleOut, onBlur: handleOut, ...rest }));
};
export const ThumbnailCardLabel = (props) => {
    return (_jsx(Text, { mt: 1, color: 'gray.700', fontSize: 'sm', lineHeight: 'shorter', userSelect: 'text', ...props }));
};
export const ThumbnailCardSubtitle = ({ children, ...props }) => {
    return (_jsx(Text, { backdropBlur: 'sm', backgroundColor: 'rgba(0,0,0,0.5)', padding: '2px 4px', borderRadius: 'sm', fontSize: 'sm', position: 'absolute', bottom: 1, right: 2, color: 'white', lineHeight: 'x-short', fontWeight: 'medium', transition: '0.3s', opacity: 0, _groupHover: {
            opacity: 1,
        }, ...props, children: children }));
};
export function ThumbnailPreview({ objectFit, Thumbnail, Subtitle, hasGradientOverlay, ...boxProps }) {
    return (_jsxs(Box, { position: 'relative', borderRadius: 'sm', border: 'solid 1px var(--play-colors-gray-100)', overflow: 'hidden', ...boxProps, children: [_jsx(AspectRatio, { ratio: 4 / 3, sx: { img: { objectFit } }, h: '100%', children: _jsx(_Fragment, { children: Thumbnail }) }), Subtitle] }));
}
export const ThumbnailCard = (props) => {
    const { Thumbnail, hoverLabel, children, Subtitle, Menu, objectFit = 'cover', 
    // be wary, this doesn't forward the polymorphism well if you use the `as` prop.
    // you will likely need to cast as any
    ThumbLinkProps, hasGradientOverlay, isMenuOpen, ThumbPreviewBoxProps = {}, version, isBulkModeEnabled, ...boxProps } = props;
    const thumbnailPreview = (_jsx(ThumbnailPreview, { objectFit, Thumbnail, Subtitle, hasGradientOverlay, ...ThumbPreviewBoxProps }, version));
    return (_jsxs(Box, { position: 'relative', ...boxProps, flexGrow: 1, children: [_jsxs(Box, { role: 'group', transformOrigin: 'bottom', transition: 'ease-out 150ms', _hover: isBulkModeEnabled
                    ? undefined
                    : {
                        // No transform scale on mobile
                        base: { transform: 'none' },
                        md: { transform: 'scale(105%)' },
                    }, 
                // If project menu is open, maintain the hover scale effect
                transform: isMenuOpen ? 'scale(105%)' : undefined, height: '100%', children: [ThumbLinkProps && !isBulkModeEnabled ? (_jsx(RouteLink, { display: 'block', role: 'group', _hover: {
                            boxShadow: shadows.md,
                        }, ...ThumbLinkProps, children: thumbnailPreview })) : (thumbnailPreview), Menu] }), children] }));
};
